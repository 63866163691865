<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <custom-loading
      :loading="loading_pedidos"
      color="greyTxt"
      opacity=".4"
    />
    <back class="mt-6" />
    <div
      class="ml-2 font-weight-medium text-h5 black--text pt-4 mb-4 d-flex align-center"
    >
      Pedidos
      <span
        v-if="cliente_nombre"
        class="ml-3 font-weight-regular text-subtitle-2 lightGreyTxt--text"
      >
        {{ cliente_nombre }}
      </span>
    </div>

    <v-row>
      <v-col
        class="d-flex align-center justify-end"
        cols="12"
      >
        <app-btn
          class="mx-2"
          color="sendBtn"
          dark
          @click="getReporte"
        >
          Generar reporte
        </app-btn>
      </v-col>
      <v-col
        v-if="showMsgNoArticulos"
        class="d-flex align-center justify-end py-0"
      >
        <span class="error--text">
          Debe seleccionar algún ítem para generar el reporte
        </span>
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <v-card
          elevation="2"
        >
          <!-- Tabla de Pedidos -->
          <generic-table
            :loading="loading_pedidos"
            :headers="headers_pedidos"
            :items="pedidos"
            :data-table="dataTablePedidos"
            height="unset"
            :sort-by="['id_pedido']"
            :options="{
              'itemsPerPage':10
            }"
            show-select
            :actions="actions_pedidos"
            item-key="idemcmc"
            @openRemitos="openRemitos"
            @openDetalles="openDetalles"
            @changeSelected="changeSelected"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { get, call } from 'vuex-pathify'
  import xlsController from '@/xls/download'

  export default {
    name: 'DashboardView',

    data () {
      return {
        dataTablePedidos: {
          search:
            {
              param: 'keyword',
            },
        },
        showMsgNoArticulos: false,
        idemcmcArray: [],
        actions_pedidos: [
          {
            icon: 'view-grid-outline',
            title: 'Remitos',
            color: 'sendBtn',
            emit: 'openRemitos',
            attr: { dark: true },
          },
          {
            icon: 'eye',
            title: 'Detalle',
            color: 'primary',
            emit: 'openDetalles',
          },
        ],

      }
    },

    computed: {
      pedidos: get('pedidos/items'),
      headers_pedidos: get('pedidos/headers'),
      loading_pedidos: get('pedidos/loading'),
      count_pedidos: get('pedidos/count'),

      cliente_id () {
        return this.$route.params?.cliente_id
      },
      cliente_nombre () {
        return this.$route.params?.cliente_nombre
      },
    },
    created () {
      this.initialize()
    },
    methods: {
      getPedidos: call('pedidos/getData'),
      getReportePedidos: call('pedidos/reporte'),
      initialize () {
        this.getPedidos({ cliente_id: this.cliente_id })
      },
      openRemitos (send) {
        this.$router.push(`/remitos/${send.idemcmc}/${send.id_pedido}/${this.cliente_nombre}`)
      },
      openDetalles (details) {
        this.$router.push(`/pedido/${details.idemcmc}/${this.cliente_nombre}/${details.id_pedido}`)
      },
      changeSelected (data) {
        this.idemcmcArray = data
        this.showMsgNoArticulos = false
      },
      async getReporte () {
        if (this.idemcmcArray?.length) {
          const { items, headers, widths } = await this.getReportePedidos({ idemcmcArray: this.idemcmcArray })
          xlsController.downloadXls(
            {
              items,
              headers,
              widths,
              fileName: 'Reporte de pedidos',
              sheetName: 'Articulos por pedidos',
              fileSubject: 'Pedidos',
            },
          )
        } else {
          this.showMsgNoArticulos = true
        }
      },
    },
  }
</script>
